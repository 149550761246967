import { useState } from "react";
import styled from "styled-components";
import { Button, IconSource } from "@shopify/polaris";
import {
  CircleDownIcon,
  CircleUpIcon,
  DeleteIcon,
  EditIcon,
} from "@shopify/polaris-icons";

interface ContentBlockContainerProps {
  desktopWidth?: string;
  mobileWidth?: string;
}

interface ContentContainerProps {
  imagePosition?: string;
}

interface ImageContainerProps {
  imageWidth?: string;
}

const QuestionContentBlocksList = ({
  contentBlocks,
  update,
  setContentBlockId,
  location,
  openContentBlockModal,
}) => {
  const [loading, setLoading] = useState(false);

  if (!contentBlocks?.[location]?.length) {
    return null;
  }

  const edit = (id) => {
    setContentBlockId(id);
    openContentBlockModal(location);
  };

  const moveUp = async (id) => {
    if (id && contentBlocks?.[location]?.length) {
      setLoading(true);
      const blockIndex = contentBlocks?.[location]?.findIndex(
        (cb) => cb?.id && cb.id === id
      );
      if (blockIndex > 0) {
        const newContentBlocks = [...contentBlocks?.[location]];
        const block = newContentBlocks[blockIndex];
        newContentBlocks[blockIndex] = newContentBlocks[blockIndex - 1];
        newContentBlocks[blockIndex - 1] = block;
        await update({
          ...contentBlocks,
          [location]: newContentBlocks,
        });
      }
      setLoading(false);
    }
  };

  const moveDown = async (id) => {
    if (id && contentBlocks?.[location]?.length) {
      setLoading(true);
      const blockIndex = contentBlocks?.[location]?.findIndex(
        (cb) => cb?.id && cb.id === id
      );
      if (blockIndex < contentBlocks?.[location]?.length - 1) {
        const newContentBlocks = [...contentBlocks?.[location]];
        const block = newContentBlocks[blockIndex];
        newContentBlocks[blockIndex] = newContentBlocks[blockIndex + 1];
        newContentBlocks[blockIndex + 1] = block;
        await update({
          ...contentBlocks,
          [location]: newContentBlocks,
        });
      }
      setLoading(false);
    }
  };

  const deleteBlock = async (id) => {
    if (id && contentBlocks?.[location]?.length) {
      setLoading(true);
      if (confirm("Are you sure you want to delete this content block?")) {
        await update({
          ...contentBlocks,
          [location]: contentBlocks?.[location]?.filter(
            (cb) => cb?.id && cb.id !== id
          ),
        });
      }
      setLoading(false);
    }
  };

  return (
    <ListContainer>
      {contentBlocks?.[location]
        ?.filter((cb) => cb?.id)
        ?.map((contentBlock, idx) => {
          const image = contentBlock?.block?.image;
          return (
            <ContentBlockContainer
              key={contentBlock.id}
              desktopWidth={contentBlock?.settings?.desktopWidth}
              mobileWidth={contentBlock?.settings?.mobileWidth}
            >
              <ContentContainer imagePosition={image?.position}>
                {image?.showImage && image?.url && (
                  <ImageContainer imageWidth={image?.width}>
                    <img src={contentBlock?.block?.image?.url} />
                  </ImageContainer>
                )}
                <ContentBlockContent
                  dangerouslySetInnerHTML={{
                    __html: `${contentBlock?.block?.content ?? ""}${
                      contentBlock?.rawHTML ?? ""
                    }`,
                  }}
                />
              </ContentContainer>
              <ContentBlockActions>
                {idx !== 0 && (
                  <Button
                    size="slim"
                    onClick={() => moveUp(contentBlock.id)}
                    icon={CircleUpIcon as IconSource}
                    loading={loading}
                  />
                )}
                {idx !== contentBlocks?.[location]?.length - 1 && (
                  <Button
                    size="slim"
                    onClick={() => moveDown(contentBlock.id)}
                    icon={CircleDownIcon as IconSource}
                    loading={loading}
                  />
                )}
                <Button
                  icon={DeleteIcon as IconSource}
                  outline
                  size="slim"
                  onClick={() => deleteBlock(contentBlock.id)}
                  destructive
                  loading={loading}
                />
                <Button
                  icon={EditIcon as IconSource}
                  size="slim"
                  onClick={() => edit(contentBlock.id)}
                  primary
                  loading={loading}
                />
              </ContentBlockActions>
            </ContentBlockContainer>
          );
        })}
    </ListContainer>
  );
};

export default QuestionContentBlocksList;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 40px auto 24px auto;
`;

const ContentBlockContainer = styled.div<ContentBlockContainerProps>`
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.3;
  padding: 10px;
  margin: 16px auto;
  width: ${(props) => props.desktopWidth ?? "900px"};
  max-width: 100%;
  @media (max-width: 768px) {
    width: ${(props) => props.mobileWidth ?? "100%"};
  }

  & * {
    text-align: left;
  }

  & ul,
  & ol {
    list-style-position: inside;
  }

  & .ql-align-right {
    text-align: right;
  }
  & .ql-align-center {
    text-align: center;
  }
  & .ql-align-left {
    text-align: left;
  }

  & h1 {
    font-size: 32px;
  }

  & h2 {
    font-size: 24px;
  }

  & h3 {
    font-size: 18px;
  }

  & h4 {
    font-size: 16px;
  }

  & h5 {
    font-size: 13px;
  }

  & h6 {
    font-size: 10px;
  }

  & a {
    color: #007ace;
    text-decoration: underline;
  }

  & .ql-indent-1 {
    padding-left: 3em;
  }

  & .ql-indent-2 {
    padding-left: 6em;
  }

  & .ql-indent-3 {
    padding-left: 9em;
  }

  & .ql-indent-4 {
    padding-left: 12em;
  }

  & .ql-indent-5 {
    padding-left: 15em;
  }

  & .ql-indent-6 {
    padding-left: 18em;
  }
`;

const ContentBlockContent = styled.div`
  padding: 16px;
  flex: 1 1 0;
  width: 100%;
`;

const ContentBlockActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px;
  gap: 8px;
  border-top: 1px solid #ccc;
  width: 100%;
  font-size: 12px;

  & span {
    flex-grow: 1;
  }
`;

const ContentContainer = styled.div<ContentContainerProps>`
  display: flex;
  flex-direction: ${(props) =>
    props?.imagePosition === "left"
      ? "row"
      : props?.imagePosition === "right"
      ? "row-reverse"
      : props?.imagePosition === "top"
      ? "column"
      : "column-reverse"};
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: ${(props) =>
      props?.imagePosition === "top" ? "column" : "column-reverse"};
  }
`;

const ImageContainer = styled.div<ImageContainerProps>`
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props?.imageWidth ?? "100%"};
  & img {
    width: 100%;
  }
`;
