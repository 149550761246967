import { Badge, Button, Select, Stack, Text } from "@shopify/polaris";
import { DeleteIcon } from "@shopify/polaris-icons";
import { MultiSelect } from "~/components";

export const MultiChoiceRule = ({
  id,
  type,
  index,
  options,
  steps,
  removeRule,
  selectedOptions,
  selectedStep,
  setSelectedOptions,
  setSelectedStep,
}) => {
  let label = "";
  let badge = null as any;
  if (type === "exact") {
    label = "If this exact combination of answers is selected";
    badge = <Badge status="success">Exact</Badge>;
  } else if (type === "open") {
    label = "If all these answers and possibly more are selected";
    badge = <Badge status="info">Open</Badge>;
  } else if (type === "broad") {
    label = "If at least one of these answers is selected";
    badge = <Badge status="warning">Broad</Badge>;
  }

  return (
    <Stack vertical spacing="tight">
      <Stack alignment="center" spacing="tight" distribution="equalSpacing">
        <Stack>
          <Text variant="bodyMd" as="h4">
            Rule {index}.
          </Text>
          {badge}
        </Stack>
        <Button
          size="slim"
          outline
          icon={DeleteIcon as any}
          destructive
          onClick={() => removeRule(id)}
        >
          Remove
        </Button>
      </Stack>
      <Stack alignment="center" spacing="tight" distribution="fillEvenly">
        <Stack.Item fill>
          <MultiSelect
            options={options.map((option) => ({
              label: option.label,
              value: option.id,
            }))}
            label={label}
            selected={selectedOptions ?? []}
            setSelected={setSelectedOptions}
          />
        </Stack.Item>
        <Stack.Item fill>
          <Select
            label="Then jump to this step"
            labelHidden={true}
            options={steps}
            placeholder="Then jump to this step"
            value={selectedStep ?? ""}
            onChange={setSelectedStep}
          />
        </Stack.Item>
      </Stack>
    </Stack>
  );
};
