import { Button, Stack } from "@shopify/polaris";
import {
  LayoutBlockIcon,
  MaximizeIcon,
  MinimizeIcon,
} from "@shopify/polaris-icons";
import { MultiChoiceRule } from "~/components/MultiChoiceRule";
import { v4 as uuidv4 } from "uuid";

export const MultiChoiceCombo = ({
  nodeId,
  options,
  steps,
  rules,
  setRules,
}) => {
  const addRule = (ruleType) => {
    setRules((prevRules) => ({
      ...prevRules,
      [nodeId]: [
        {
          id: uuidv4(),
          selectedOptions: [],
          selectedStep: null,
          type: ruleType,
        },
        ...(prevRules?.[nodeId] || []),
      ],
    }));
  };

  const removeRule = (id) => {
    setRules((prevRules) => ({
      ...prevRules,
      [nodeId]: prevRules[nodeId].filter((rule) => rule.id !== id),
    }));
  };

  return (
    <Stack vertical spacing="loose">
      <Stack>
        <Button
          onClick={() => addRule("exact")}
          icon={MinimizeIcon as any}
          size="slim"
        >
          Add Exact Rule
        </Button>
        <Button
          onClick={() => addRule("open")}
          icon={LayoutBlockIcon as any}
          size="slim"
        >
          Add Open Rule
        </Button>
        <Button
          onClick={() => addRule("broad")}
          icon={MaximizeIcon as any}
          size="slim"
        >
          Add Broad Rule
        </Button>
      </Stack>

      {rules?.[nodeId]?.map((rule, index) => (
        <MultiChoiceRule
          type={rule.type}
          key={rule.id}
          id={rule.id}
          index={index + 1}
          options={options}
          steps={steps}
          removeRule={removeRule}
          selectedOptions={rule.selectedOptions}
          selectedStep={rule.selectedStep}
          setSelectedOptions={(selectedOptions) => {
            setRules((prevRules) => ({
              ...prevRules,
              [nodeId]: prevRules[nodeId].map((r) =>
                r.id === rule.id ? { ...r, selectedOptions } : r
              ),
            }));
          }}
          setSelectedStep={(selectedStep) => {
            setRules((prevRules) => ({
              ...prevRules,
              [nodeId]: prevRules[nodeId].map((r) =>
                r.id === rule.id ? { ...r, selectedStep } : r
              ),
            }));
          }}
        />
      ))}
    </Stack>
  );
};
