import { useEffect, useState } from "react";
import {
  Modal,
  Stack,
  Button,
  TextField,
  Checkbox,
  Select,
  Thumbnail,
  Text,
} from "@shopify/polaris";
import { Box } from "@storyofams/react-ui";
import styled from "styled-components";
import RichTextEditor from "~/components/preview/RichTextEditor";
import { v4 as uuidv4 } from "uuid";
import { MediaModal } from "~/components/MediaInput/MediaModal";
import Editor from "@monaco-editor/react";

const UPLOAD_API_KEY = "public_FW25bYGAgB45TKGbWWuMMhPy32FL";

// @ts-ignore
const upload = Upload({ apiKey: UPLOAD_API_KEY });

const QuestionContentBlockModal = ({
  open,
  onClose,
  onOpen,
  location,
  id,
  save,
  contentBlocks,
}) => {
  const [block, setBlock] = useState({
    content: "",
    image: {} as any,
  });
  const [desktopWidth, setDesktopWidth] = useState("900px");
  const [mobileWidth, setMobileWidth] = useState("100%");
  const [showFilePicker, setShowFilePicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [rawHTML, setRawHTML] = useState("");

  useEffect(() => {
    if (id && contentBlocks?.[location]?.length) {
      const contentBlock = contentBlocks?.[location]?.find(
        (cb) => cb?.id === id
      );
      if (contentBlock?.block) {
        setBlock(contentBlock.block);
      }
      if (contentBlock?.settings?.desktopWidth) {
        setDesktopWidth(contentBlock?.settings?.desktopWidth);
      }
      if (contentBlock?.settings?.mobileWidth) {
        setMobileWidth(contentBlock?.settings?.mobileWidth);
      }
      if (contentBlock?.rawHTML) {
        setRawHTML(contentBlock.rawHTML);
      }
    } else {
      setBlock({
        content: "",
        image: {},
      });
      setDesktopWidth("900px");
      setMobileWidth("100%");
    }
  }, [contentBlocks, id]);

  const openFilePicker = () => {
    setShowFilePicker(true);
  };

  const closeFilePicker = () => {
    setShowFilePicker(false);
  };

  const uploadFile = async (file) => {
    try {
      const fileName = file?.name ?? "image";

      const { fileUrl } = await upload.uploadFile(file, {
        path: {
          folderPath: "/uploads/ppf",
          fileName: "{UNIQUE_DIGITS_8}{ORIGINAL_FILE_EXT}",
        },
        onProgress: () => {},
      });
      setBlock({
        ...block,
        image: {
          ...block?.image,
          name: fileName,
          url: fileUrl,
        },
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const setFile = (name, url) => {
    setBlock({
      ...block,
      image: {
        ...block?.image,
        name,
        url,
      },
    });
    closeFilePicker();
  };

  const handleDropZoneDrop = async (
    _dropFiles,
    acceptedFiles,
    _rejectedFiles
  ) => {
    const file = acceptedFiles?.[0];
    if (file) {
      setUploading(true);
      await uploadFile(file);
      setUploading(false);
    }
    closeFilePicker();
  };

  const saveContentBlock = async () => {
    setLoading(true);
    if (!id) {
      id = uuidv4();
    }
    await save(location, id, {
      id,
      block,
      settings: {
        desktopWidth,
        mobileWidth,
      },
      rawHTML,
    });
    onClose();
    setLoading(false);
  };

  const updateBlockContent = (content) => {
    setBlock({
      ...block,
      content,
    });
  };

  return (
    <>
      <Modal
        open={open && !showFilePicker}
        onClose={onClose}
        title="Add a Content Block"
        primaryAction={{
          content: "Save Content Block",
          onAction: saveContentBlock,
          loading,
          disabled:
            block?.content === "" &&
            !rawHTML?.length &&
            !block?.image?.showImage,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: onClose,
          },
        ]}
        large
      >
        <Modal.Section>
          <SettingsContainer>
            <TextField
              label="Desktop Width"
              value={desktopWidth}
              onChange={(v) => setDesktopWidth(v)}
              autoComplete="off"
            />
            <TextField
              label="Mobile Width"
              value={mobileWidth}
              onChange={(v) => setMobileWidth(v)}
              autoComplete="off"
            />
          </SettingsContainer>
          <Stack vertical spacing="loose">
            <Box mt={2} mb={2}>
              <Stack vertical>
                <ImageSettingsContainer>
                  <HorizontalSettingsContainer>
                    <Checkbox
                      label="Include an image"
                      checked={block?.image?.showImage}
                      onChange={() => {
                        setBlock({
                          ...block,
                          image: {
                            ...block?.image,
                            showImage: block?.image?.showImage ? false : true,
                          },
                        });
                      }}
                    />
                    {!!block?.image?.showImage && !block?.image?.name && (
                      <Button onClick={openFilePicker} plain size="slim">
                        Upload Image
                      </Button>
                    )}
                    {!!block?.image?.showImage &&
                      !!block?.image?.name &&
                      !!block?.image?.url && (
                        <ImageNameContainer>
                          <Thumbnail
                            source={block?.image?.url}
                            alt={block?.image?.name}
                            size="small"
                          />
                          <ImageName>{block?.image?.name}</ImageName>
                          <Button
                            plain
                            size="slim"
                            destructive
                            onClick={() =>
                              setBlock({
                                ...block,
                                image: {
                                  ...block?.image,
                                  name: null,
                                  url: null,
                                },
                              })
                            }
                          >
                            Remove
                          </Button>
                        </ImageNameContainer>
                      )}
                  </HorizontalSettingsContainer>
                  {!!block?.image?.showImage && (
                    <HorizontalSettingsContainer>
                      <TextField
                        label="Image Width (Desktop)"
                        value={block?.image?.width ?? "100%"}
                        onChange={(width) =>
                          setBlock({
                            ...block,
                            image: {
                              ...block?.image,
                              width,
                            },
                          })
                        }
                        placeholder="100%"
                        autoComplete="off"
                      />
                      <TextField
                        label="Image Width (Mobile)"
                        value={block?.image?.widthMobile ?? "100%"}
                        onChange={(widthMobile) =>
                          setBlock({
                            ...block,
                            image: {
                              ...block?.image,
                              widthMobile,
                            },
                          })
                        }
                        placeholder="100%"
                        autoComplete="off"
                      />
                      <Select
                        label="Image Position"
                        options={[
                          { label: "Top", value: "top" },
                          { label: "Bottom", value: "bottom" },
                          { label: "Left", value: "left" },
                          { label: "Right", value: "right" },
                        ]}
                        value={block?.image?.position ?? "bottom"}
                        onChange={(position) =>
                          setBlock({
                            ...block,
                            image: {
                              ...block?.image,
                              position,
                            },
                          })
                        }
                      />
                    </HorizontalSettingsContainer>
                  )}
                </ImageSettingsContainer>
                <RichTextEditor
                  variantId={id}
                  value={block?.content}
                  setValue={(v) => updateBlockContent(v)}
                />
                <Text as="h3" variant="headingMd">
                  Raw HTML
                </Text>
                <Text as="p" variant="bodyMd">
                  WARNING! Raw HTML gets rendered as is and can break the quiz
                  code. Please use with caution.
                </Text>
                <Editor
                  height="20vh"
                  defaultLanguage="html"
                  value={rawHTML}
                  onChange={(v) => setRawHTML(v ?? "")}
                />
              </Stack>
            </Box>
          </Stack>
        </Modal.Section>
      </Modal>

      <MediaModal
        isOpen={showFilePicker}
        setOpen={setShowFilePicker}
        onDrop={async (v) => {
          await handleDropZoneDrop(null, v, null);
        }}
        onSelectImage={(newImage) => {
          setFile(newImage?.blurhash ?? uuidv4(), newImage?.url);
        }}
        onSelectVideo={(newVideo) => {
          setFile(newVideo?.blurhash ?? uuidv4(), newVideo?.url);
        }}
        onSelectGif={(newGif) => {
          setFile(newGif?.blurhash ?? uuidv4(), newGif?.url);
        }}
        isBusy={uploading}
        types={["image", "gif"]}
      />
    </>
  );
};

export default QuestionContentBlockModal;

const SettingsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  margin: 16px 0;
`;

const ImageSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
`;

const HorizontalSettingsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;

  & > label {
    min-width: 150px;
  }
`;

const ImageNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
`;

const ImageName = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #637381;
`;
