import { Box } from "@storyofams/react-ui";
import { motion } from "framer-motion";

import config from "~/config";
import { useFlow } from "~/hooks";

import { ProductCard } from "../ProductCard";

const item = {
  hidden: { opacity: 0, y: 10 },
  show: { opacity: 1, y: 0, transition: config.transition },
};

const AnimatedBox = motion(Box);

interface ResultProps {
  idx: number;
  resultsPerRow: number;
  resultsPerRowMobile: number;
}

export const Result = ({
  idx,
  resultsPerRow = 3,
  resultsPerRowMobile = 1,
}: ResultProps) => {
  const { data } = useFlow();

  const desktopPercentage = `${100 / resultsPerRow}%`;
  const mobilePercentage = `${100 / resultsPerRowMobile}%`;

  return (
    <AnimatedBox
      variants={item}
      width={{
        _: "100%",
        sm: `calc(${mobilePercentage} - 16px)`,
        lg: `calc(${desktopPercentage} - 16px)`,
      }}
      mx={1}
      mb={[1, 2]}
    >
      <ProductCard
        primaryColor={data?.flows?.[0]?.primaryColor}
        enableAddToCart={!!data?.flows?.[0]?.enableAddToCart}
        noCart={!!data?.flows?.[0]?.noCart}
        openInNewTab={!!data?.flows?.[0]?.openInNewTab}
        enableReviews={!!data?.flows?.[0]?.enableReviews}
        reviewsSource={data?.flows?.[0]?.reviewsSource ?? "shopify"}
        cartPage={data?.flows?.[0]?.cartPage ?? "default"}
        showProductDescription={!!data?.flows?.[0]?.showProductDescription}
        showShortDescription={!!data?.flows?.[0]?.showShortDescription}
        customDescriptionMetafield={
          data?.flows?.[0]?.customDescriptionMetafield ?? "lantern_description"
        }
        showVisualMatchBlock={!!data?.flows?.[0]?.showVisualMatchBlock}
        price="Price"
        title={`#${idx + 1} Product - Result`}
        type="white-bg"
      />
    </AnimatedBox>
  );
};
